.show-grid {
  text-align: center;
}

.survey-input {
  margin-left: 25%;
  margin-right: 25%;
  background: "#ffffff";
}

.dinamic_questions_wrap {
  width: 600px;
  margin: auto;
}
.dinamic_questions_parent {
  /*height: calc(100vh - 250px);*/
  overflow: auto;
}
.dinamic_quest_header {
  text-align: center;
  padding-top: 5px;
  margin-bottom: 10px;
}
.dinamic_quest_header h3 {
  font-size: 26px;
  margin-bottom: 5px;
}
.dinamic_quest_header p {
  font-size: 12px;
  margin-bottom: 5px;
}
.dinamic_quest_block {
  padding: 15px;
  background: #f7f7f7;
  margin-bottom: 2px;
  border-left: 5px solid #c6aeff;
}
.dinamic_quest_block .form-group {
  margin-bottom: 5px;
}

.label-part {
  width: 20%;
  float: left;
  font-weight: 600;
  font-size: 12px;
  line-height: 38px;
  text-transform: uppercase;
}

.ans-part {
  width: 80%;
  float: left;
}
.dinamic_quest_body {
  padding: 20px;
  position: relative;
  border-bottom: 1px dashed #b3b3b3 !important;
}
.deletbtn {
  position: absolute;
  top: 20px;
  right: -50px;
}

.addbtn {
  position: absolute;
  top: 23px;
  right: -20px;
  cursor: pointer;
  color: #007bff;
}

.dinamic_quest_block .form-group:last-child {
  margin-bottom: 0;
}
.gallery {
  padding: 50px;
}
.gallery-folder i {
  display: block;
  font-size: 40px;
  color: #f3d67e;
}
.fullwidthgallerg > div {
  display: inline-block;
  padding-right: 80px;
  cursor: pointer;
  margin-bottom: 40px;
}
.gallery .gallery {
  display: block;
}
.gallery-mail {
  width: 83%;
  padding: 15px;
}
.gallery-mail-innerwrap {
  padding: 30px 20px;
  background: #ffffff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  height: 90%;
  overflow-y: auto;
  margin-top: 3%;
}
.gallery-mail-innerwrap .gallery {
  padding: 0;
}
.gallerysave {
  margin-top: 20px;
  color: #fff;
  background-color: #2196f3;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  min-height: 36px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 4px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
}
.gallery .DropZone {
  padding: 20px;
  margin-bottom: 40px;
}
.listofgallery {
  width: 70px;
  height: 70px;
  margin-right: 15px;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0px 0px 3px silver;
}
.listofgallery img {
  width: 90%;
  padding: 10px;

  max-width: 90%;
  max-height: 90%;
  height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.gallerylistimages .DropZone {
  margin-top: 20px;
}
.gallerydivwrap {
  display: block !important;
}
.galleryback {
  margin-bottom: 20px;
  display: block;
}
.errordeletes {
  font-size: 14px;
  padding: 10px;
  color: #e10050;
  padding-top: 0;
}
.errordeletes i {
  padding-right: 3px;
}
.pr-num-scale span {
  padding: 1px;
}
.pr-num-scalesucc {
  line-height: 38px;
  height: 38px;
  padding: 0px 8px;
  font-weight: 600;
  margin: 0px 20px;
  font-size: 16px;
  margin-left: 0;
  color: #f04134;
  padding-bottom: 5px;
  /*background: #f3f3f3; */
  border-bottom: 2px solid #f04134;
}
.pr-num-scalesucc_wrong {
  margin-right: 0;
  margin-left: 20px;
  color: green;
}
.priview_tablehear td {
  padding: 5px 20px;
  font-weight: 500;
  color: #000;
}
.priview_tablebody td {
  padding: 5px 20px;
  text-align: center;
  background: #f7f7f7;
}
.priview_tablebody td:first-child {
  margin-right: 10px;
  /* background: red; */

  color: #828282;
}
.priview_tablehear td {
}
.priview_tablebody td:first-child {
  color: #333333;
  border-bottom: 1px solid #cec6c6;
  background: #dfdfdf;
}
.priview_tablehear td:first-child {
  background: none;
}
.pr-num-scale img {
  width: 20px;
}
.prev_tablealtcls {
  font-size: 14px;
  margin: 0px auto;
}
.prev_tablealtcls td img {
  width: 16px;
}

.listofgalleryalters {
  position: relative;
}
.listofgalleryalters i {
  position: absolute;
  right: -5px;
  top: -8px;
  width: 17px;
  font-size: 12px;
  height: 17px;
  cursor: pointer;
  text-align: center;
  line-height: 17px;
  border-radius: 50%;
  color: #ffffff;
  background: #df3747;
}
.error {
  color: red;
  display: block;
  padding: 5px 0px;
  font-size: 12px;
  font-style: italic;
}
.fontbold {
  font-weight: bold;
}
.fontitalic {
  font-style: italic;
}
.fontunderline {
  text-decoration: underline;
}
.fontstrike {
  text-decoration: line-through;
}
.textcenter {
  text-align: center;
}
.textright {
  text-align: right;
}
.prevbox .bbtom {
  max-width: 500px;
  display: block;
  margin: auto;
  text-align: center;
}

.listofgalleryalters {
  width: 18%;
  height: 100px;
  background: #f9f9f9;
  margin-right: 2%;
  transition: 0.3s;
}
.listofgalleryalters img {
  width: auto;
}
.listofgalleryalters i {
  color: #333333;
  top: 5px;
  width: 19px;
  height: 19px;
  box-shadow: 0px 0px 5px #bebaba;
  line-height: 19px;
  right: 5px;
  border-radius: 0;
  background: #ffffff;
}
.gallerylistimages .DropZone {
  border: 2px dashed #d15c17 !important;
  color: #d15c17;
  background: #fff7f2;
}
.listofgalleryalters:hover {
  box-shadow: 0px 0px 11px silver;
}
.gallery-folder {
  font-size: 12px;
  text-align: center;
}
.gallery-folder i {
  display: block;
  font-size: 80px;
}
.galleryparebt {
  padding: 0px !important;
  background: #fdfdfd;
  margin-right: 50px;
  box-shadow: 0px 0px 2px #d0cfcf;
}
.folder-name-image {
  padding: 10px;
  padding-bottom: 0 !important;
}
.folder-name-test {
  padding: 5px;
  border-top: 1px solid #e7e6e6;
  background: #f1f1f1;
}
.conditionerror {
  text-align: center;
  padding: 6px;
  color: red;
  font-style: italic;
  margin-top: 5px;
  font-size: 14px;
  background: var(--red);
  color: #ffffff;
  font-style: normal;
  border-radius: 4px;
  width: 382px;
  margin: 10px auto;
}
.conditionerror i {
  font-size: 20px;
  padding-right: 4px;
  vertical-align: middle;
}
.bottom-errormessageshow {
  position: absolute;
  right: 100px;
  top: 5%;
  text-align: center;
  padding: 6px;
  color: red;
  font-style: italic;
  margin-top: 5px;
  font-size: 14px;
  background: var(--red);
  color: #ffffff;
  font-style: normal;
  border-radius: 4px;
  width: 382px;
  margin: 10px auto;
}
.bottom-errormessageshow i {
  font-size: 20px;
  padding-right: 4px;
  vertical-align: middle;
}
.prevbox .bbtom.bbtom-news {
  display: inline-block;
  max-width: unset;
  text-align: left;
  margin-left: 28px;
}
.new-div-parts img {
  max-width: 400px;
}
.prevboxsingle .nextq {
  bottom: -20px;
}
.new-div-parts {
  padding: 5px 0px;
}
.icontype_cls_name {
  color: #333333;
  font-size: 14px;
  display: inline-block;
  padding-right: 10px;
}
.droppertotalclsinnerclsn a {
  margin-left: 0;
}

.imgdpgallnparent {
  border-top: 1px solid #ccc;
  margin-top: 5px;
  padding-top: 5px;
}
.new-img-prev-up img {
  width: 39px;
  margin-right: 7px;
}
.new-img-prev-up {
  padding-top: 5px;
}
.autosave {
  position: absolute;
  right: 0px;
  top: 0px;
  background: #4a7f20;
  display: inline-block;
  padding: 1px 10px;
  font-size: 12px;
  font-style: italic;
  color: #ffffff;
}
.autosave {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
