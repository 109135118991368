.login-page {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
}

.buttonColor {
  background-color: #3b2167 !important;
  border: 3px solid #ece7f5 !important;
  min-width: 150px !important;
  color: #fff !important;
  margin-left: 32px !important;
}
/* 
#myVideo {
  position: fixed;
  left: 0;
  top: 0;
  width: auto;
  width: 100%;
} */

/* .videoOver {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 122.5%;
  background-color: rgba(5, 28, 58, 0.6);
} */

.midl {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 1);
  max-width: 340px;
  margin: 26% auto 0;
}

.Login-header {
  text-align: left;
  height: 20%;
  padding: 2% 5%;
}

.Login-logo {
  height: 75px;
  padding: 0 5%;
  margin-bottom: 8%;
  border-radius: 4px;
  z-index: 3;
}

.Login-body {
  text-align: left;
  display: flex;
  color: #000;
  margin-right: 0px !important;
  
}

.Login-left {
  padding: 2% 0% 0% 10% !important;
  width: 50%;
}

.Login-right {
  width: 40%;
  padding: 0% !important;
}

.Login-title {
  font-size: 18px;
  font-weight: 900;
}

.Login-subtitle {
  font-size: 14px;
}

.login-shadow {
  opacity: 0.1;
  z-index: 2;
}

.login-form {
  width: 330px;
  padding: 1% 0;
}

.input-blue {
  font-family: "Open Sans", sans-serif !important;
  display: block !important;
  padding: 5px !important;
  border-radius: 4px !important;
  border: 0.5px solid #989898 !important;
  background: #fff !important;
  color: #000 !important;
  outline: none;
  font-weight: 10;
}

.input-blue::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #666 !important;
  opacity: 1 !important;

  /* Firefox */
}

.input-blue:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #666 !important;
}

.input-blue::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #666 !important;
}

.input-blue:hover,
.input-blue:focus {
  border: 1px solid #989898 !important;
  box-shadow: 0 0 4px 1px #666 !important;
}

@media only screen and (max-width: 768px) {
  .Login-header {
    height: 15%;
  }

  .Login-body {
    height: 75%;
  }

  .Login-left {
    width: 100%;
    justify-content: center;
    padding: 5% !important;
  }

  .Login-right {
    display: none;
  }
}

.button:focus {
  outline: 0;
}

.text-link {
  color: #fff;
  font-size: 12pt;
  z-index: 2;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.alert {
  padding: 0% !important;
}

/* Login page divide in two area */
.left-area {
  background: #7c54e0;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #9f79ff, #7c54e0);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #9f79ff, #7c54e0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  height: 100%;
  width: 65%;
  float: left;
}
.purple-overlay {
  background: rgba(142, 103, 240, .4);
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
}
.right-area {
  height: 100%;
  width: 35%;
  float: right;
  z-index: 100;
  background: #fff;
  border-left: 4px solid rgba(82, 48, 170, .95);
  position: absolute;
  right: 0;
}

.custom-w-300 > div > div {
  width: 300px;
}
.margin-adjust-left-32 {
  margin-left: 32px;
}

.Login-img { 
  height: 100%;
}