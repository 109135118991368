.body-left {
  width: 100%;
  background: #fff;  /* fallback for old browsers */
  transition: all 0.3s;
  -webkit-box-shadow: 0px 1px 3px rgba(98, 84, 119, .35);
  box-shadow: 0px 1px 3px rgba(98, 84, 119, .35);
  position: fixed;
}

.sidebar-footer {
  top: 0;
  right: 0;
  clear: both;
  display: block;
  position: fixed;
  width: 120px;
  transition: all 0.3s;
  cursor: pointer;
}

.sidebar-footer-shrink {
  top: 0;
  right: 0;
  clear: both;
  display: block;
  position: fixed;
  width: 120px;
  color: #f00;
  transition: all 0.3s;
  cursor: pointer;
}
.body-left .sidebar-footer .metismenu-link {
  float: right;
  width: 100%;
  padding: 28px 0 27px 0;
  color: #de2ed4;
  text-align: center;
}


.fa-icon {
  color: #fff !important;
}

.body-left-collapsed {
  width: 4%;
  height: 100%;
  background: #7c54e0;
  transition: all 0.3s;
}

ul.left-nav {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  height: 100%;
}

ul.left-nav li {
  height: 40px;
  line-height: 35px;
  color: #c8c8c8;
  padding: 2% 5%;
  cursor: pointer;
  font-size: 14px;
}

.nav-active {
  background: #c71585;
  border-right: 5px solid #c71585;
  color: #fff !important;
}

ul.left-nav li:hover,
.nav-active:hover {
  color: #c71585 !important;
  background: #c71585;
}

.nav-active:hover {
  border-right: 5px solid #c71585;
}

.nav-active:hover .nav-chevron {
  color: #1976d2;
}

.nav-chevron {
  float: right;
  margin-top: 9px;
  color: #c71585;
}

.floating-arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #d5d5d5;
  border-radius: 0;
  transition: all 0.3s;
  cursor: pointer;
  z-index: 10;
  display: none;
}

.floating-arrow-expanded {
  left: 17.5%;
}

.floating-arrow-collapsed {
  left: 4%;
}

@media only screen and (max-width: 768px) {
  ul.left-nav li {
    height: 100%;
    display: none;
  }

  ul.left-nav li:first-child {
    display: block;
  }
}

.metismenu-link.has-active-child {
  background: #c71585 !important;
  color: rgb(219, 217, 217) !important;
}

.metis-menu-parent {
  background: transparent !important;
}

.metismenu-container {
  background: transparent;
}

.metismenu-container .metismenu-item {
  float: left;

}
.sidebar-footer .metismenu-container .metismenu-item {
  float: right;
  width: 100%;
}
a:hover {
  text-decoration: none !important;
}

.metismenu-link.active {
  background: #8e67f0 !important;
  box-shadow: 0 0 18px rgba(88, 58, 165, .1);
}

.metismenu {
  font-size: 12pt !important;
  margin-right: 120px;
}

.metismenu-item :hover {
  transform: scale(1.025, 1.025);
  z-index: 1;
}

.metismenu::after {
  box-shadow: none !important;
}

.menu-logo {
  width: 80%;
  height: 20%;
  cursor: pointer;
  margin: 5% 0;
}

.menu-logo-wrapper {
  width: 260px;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
  float: left;
}

.fa {
  font-size: 1rem;
}

.body-left .metismenu-container { 
  float: right;
}
.body-left .sidebar-footer .metismenu-container {
  float: left;
  width: 100%;
}
.body-left I.metismenu-icon {
  width: 100% !important;
  text-align: center;
  font-size: 2em;
}

@media only screen and (min-width: 1277px) {
  .body-left {
    width: 100%;
  }
}
