.list-box > div:hover {
  box-shadow: none;
  transform: scale(1, 1);
  z-index: 1;
  transition: all 0.5s;
}

.view-switch-button:not(.btn-active) {
  font-size: 0.97rem !important;
  margin: 0%;
  background: #fff !important;
}
.view-switch-button.btn-active {
  font-size: 0.97rem !important;
  margin: 0%;
  background: #444 !important;
  color: #fff;
}
.cgrid {
  padding: 0px !important;
}

h6 {
  margin-left: 10px;
}

.body-left .metismenu-link {
  color: #000000;
  text-shadow: none;
  padding: 0;
  margin: 0;
  line-height: 1.25em !important;
  font-size: 1.15rem;
  padding: 5px 25px 27px 25px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .5px;
}

.SurveyCard-card-179 {
  border-radius: 0 !important;
}

