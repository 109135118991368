.login-page {
    width: 100%;
    height: 100%;
 
}

.login-button {
    border-radius: 10 !important;
    background-color: #074e9e !important;
    border: 1px solid #fff !important;
    font-size: 11px !important;
    min-width: 210px !important;
}

#myVideo {
    position: fixed;
    left: 0;
    top: 0;
    width: auto;
    width: 100%;
}

.videoOver {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 122.5%;
    background-color: rgba(5, 28, 58, 0.6);
}

.Login-header {
    text-align: left;
    height: 20%;
    padding: 2% 5%;
}



.Login-body {
    text-align: left;
    display: flex;
    color: #000;
    margin-right: 0px !important;
}

.Login-left {
    padding: 2% 0% 0% 10% !important;
    width: 50%;
}

.Login-right {
    width: 40%;
    padding: 0% !important;
}

.Login-title {
    font-size: 18px;
    font-weight: 900;
}

.Login-subtitle {
    font-size: 14px;
}

.login-shadow {
    opacity: 0.1;
    z-index: 2;
}

.login-form {
    width: 330px;
    padding: 1% 0;
}

.input-blue {
    font-family: "Open Sans", sans-serif !important;
    display: block !important;
    padding: 5px !important;
    border-radius: 4px !important;
    border: 0.5px solid #989898 !important;
    background: #fff !important;
    color: #000 !important;
    outline: none;
    font-weight: 10;
}

.input-blue::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #666 !important;
    opacity: 1 !important;

    /* Firefox */
}

.input-blue:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #666 !important;
}

.input-blue::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #666 !important;
}

.input-blue:hover,
.input-blue:focus {
    border: 1px solid #989898 !important;
    box-shadow: 0 0 4px 1px #666 !important;
}

@media only screen and (max-width: 768px) {
    .Login-header {
        height: 15%;
    }

    .Login-body {
        height: 75%;
    }

    .Login-left {
        width: 100%;
        justify-content: center;
        padding: 5% !important;
    }

    .Login-right {
        display: none;
    }
}

.button:focus {
    outline: 0;
}



.alert {
    padding: 0% !important;
}