@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url("https://use.fontawesome.com/releases/v5.3.1/css/all.css");

*,
*:active,
*:hover,
*:focus {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
}

body {
  font-family: 'Open Sans', sans-serif !important;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h5 {
  color: #666;
  margin: 0 0 10px 0;
}

.App-body {
  width: 100%;
  height: 100%;
  display: flex;
  background: #f2f1f5;
}

.body-full {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1% 0% 1% 1%;
  justify-content: center;
  background: #ececec;
  /* overflow-y: hidden !important; */
  overflow-y: auto;

  transition: all 0.5s;
}

.body-full-expanded {
  width: 100%;
  margin-top: 80px;

}

.body-full-collapsed {
  width: 96%;
}

.body-form {
  display: flex;
  flex-wrap: wrap;
  padding: 1%;
  justify-content: center;
  overflow-y: auto;
  transition: all 0.5s;
}

.body-form-expanded {
  width: 100%;
  margin-top: 85px;
}

.body-form-collapsed {
  width: 96%;
}

.body-center {
  width: 57%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1%;
  background: #ececec;
  padding-right: 0% !important;
}

.body-right {
  width: 23%;
  height: 98%;
  background: #ececec;
  display: flex;
  flex-wrap: wrap;
  padding-top: 4%;
  justify-content: center;
  margin-right: 3%;
}

@media only screen and (max-width: 768px) {
  .App-body {
    display: block;
  }

  .body-left,
  .body-center,
  .body-right {
    width: 100% !important;
    height: fit-content !important;
  } 

  .statusbox:first-child {
    min-width: 50%;
  }

  .body-full {
    width: 100%;
  }

  .body-form {
    width: 100%;
  }

  .list-box>div {
    flex-basis: 100%;
  }

  .list-box .MuiGrid-container-4 {
    margin: 0 !important;
  }

  #filler {
    display: none;
  }

  #gridHeader>div {
    margin: 5px 0;
  }
}

.list-box {
  width: 100%;
  float: left;
  flex-wrap: wrap;
  justify-content: left;
  padding: 0% 20px;
}

.list-box>div:hover {
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, .6);
  transform: scale(1.025, 1.025);
  z-index: 1;
  transition: all 0.5s;
}

.MuiGrid-item-5 {
  align-self: center;
}

#gridHeader .MuiGrid-item-5 {
  text-align: center;
}

.DropZone {
  border: 1px dashed #888 !important;
  font-size: 16px !important;
  font-weight: 100 !important;
}

.form-control {
  border-radius: 0 !important;
}

.grid-header {
  width: 100%;
  margin-left: 4%;
}

.rt-thead {
  background: #074e9e !important;
}
.scrollbar-container {
    height: 100% !important;
	width: 100%;
    
  }
  td[class^="MuiTableCell-root"] {
    height: 46px !important;
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
  }
  td[class^="MuiTableCell-root"] div[class^="MuiCardActions-root"] {
    padding: 0 !important;
  }
  th span[class^="MuiTableSortLabel-active"] {
    color: #ffe665 !important;
  }
  
  
